import { gql, useMutation } from '@apollo/client'
import { useDebouncedValue } from '@shopify/react-hooks'
import React, { useEffect, useState, VFC } from 'react'
import { Button, FormField } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import { ChargebeeCoupon, CouponData } from '../../../utils/coupon'
import { SimpleForm } from '../../../components/SimpleForm'
import { SimpleText } from '../../../components/SimpleText'
import { toMoneyWithoutCalculation as toMoney, toPercent, resolveCurrency } from '../../../shared/format'
import Router from '../../../shared/router'
import Cookies from 'universal-cookie'
import Notifications from '../../../shared/notifications'
import { useTranslation } from 'react-i18next'
const styles = require('./Discount.module.scss')
const cookies = new Cookies()

const ArrowUp = require('../../../assets/images/icons/icon-arrow-up.svg')
const ArrowDown = require('../../../assets/images/icons/icon-arrow-down.svg')

type Props = {
  planId: string
  current?: ChargebeeCoupon
  onCouponFound: (data: ChargebeeCoupon) => void
  onRemoveCoupon: () => void
  showCouponInput?: boolean
};

const Discount: VFC<Props> = ({ planId, current, onCouponFound, onRemoveCoupon, showCouponInput = false }) => {
  const { t } = useTranslation()
  const [submit, { data, loading, error }] = useMutation<CouponData>(gql`
    mutation($code: String!, $priceId: ID, $currency: String) {
      coupon: redeemCoupon(code: $code, itemPriceId: $priceId, currency: $currency) {
        id
        name
        discountType
        discountPercentage
        discountAmount
      }
    }
  `)

  const [code, setCode] = useState<string>(current?.id || '')
  const debouncedCode = useDebouncedValue<string>(code, { timeoutMs: 1000 })
  const [isOpen, setIsOpen] = useState<boolean>(!!current || showCouponInput)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>(current)
  const [hasDebounced, setHasDebounced] = useState<boolean>(false)
  const handleConfirm = () => {
    coupon && onCouponFound && onCouponFound(coupon)
  }

  const handleCodeSubmit = async () => {
    if (debouncedCode !== coupon?.id) {
      const variables = { code: debouncedCode, priceId: planId, currency: resolveCurrency() }
      submit({ variables, fetchPolicy: 'no-cache' }).catch(() => {
        Notifications.error(t('checkout.sections.coupon.error'))
      })
    }
  }

  const removeCoupon = () => {
    setCoupon(undefined)
    setCode('')
    setHasDebounced(false)
    cookies.remove('coupon', { path: '/' })

    onRemoveCoupon()
  }

  const cleanUrl = () => {
    if (Router.qs.coupon) {
      const params = new URLSearchParams(location.search)
      params.delete('coupon')
      cookies.remove('coupon')
      window.history.replaceState(null, '', `?${params}${location.hash}`)
    }
  }

  const toggleForm = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (debouncedCode) {
      setHasDebounced(true)
      handleCodeSubmit()
    }

    if (debouncedCode.length === 0 && hasDebounced) {
      removeCoupon()
    }
  }, [debouncedCode, planId])

  useEffect(() => {
    if (!data?.coupon || coupon?.id === data?.coupon?.id) {
      return
    }

    setCoupon(data?.coupon)
    if (data?.coupon) {
      onCouponFound(data?.coupon)
    }
  }, [data?.coupon])

  useEffect(() => {
    if (showCouponInput) {
      setIsOpen(true)
    }
  }, [showCouponInput])

  useEffect(() => {
    const couponCode = Router.qs.coupon || cookies.get('coupon')
    if (couponCode && couponCode !== code) {
      setCode(couponCode as string)
      setIsOpen(true)
    }
  }, [Router.qs.coupon, location.search])

  return (
    <SimpleBox className={styles.discountForm}>
      <SimpleBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="coupon-header"
      >
        <SimpleText size="buttonText" color="grey">
          {t('checkout.sections.coupon.title')}
        </SimpleText>
        <a onClick={toggleForm} className={styles.toggleButton}>
          <img src={isOpen ? ArrowUp : ArrowDown} />
        </a>
      </SimpleBox>
      {isOpen && (
        <>
          <SimpleBox marginTop="20px" className="coupon-wrapper">
            <FormField>
              <label htmlFor="code" className="show-on-web">{t('checkout.sections.coupon.label')}</label>
              <SimpleBox
                display="grid"
                gap="10px"
                gridTemplateColumns="auto 88px"
                background="#F5F6F9"
                borderRadius={8}
                alignItems="center"
                paddingRight="8px"
                border={error ? '1px solid #FB0023' : undefined}
                className="coupon-body"
              >
                <SimpleForm.Input
                  id="code"
                  value={code}
                  type="text"
                  size="small"
                  onChange={({ value }) => setCode(value.toUpperCase())}
                  showValidation={coupon !== undefined}
                  fluid
                  className={styles.codeInput}
                  placeholder={t('checkout.sections.coupon.placeholder')}
                />
                {coupon && (
                  <Button
                    size="small"
                    fluid
                    className={styles.removeButton}
                    onClick={() => {
                      removeCoupon()
                      cleanUrl()
                    }}
                    loading={loading}
                  >
                    {t('checkout.sections.coupon.remove')}
                  </Button>
                )}
                {!coupon && (
                  <Button
                    className={styles.applyButton}
                    size="small"
                    fluid
                    primary
                    color="red"
                    onClick={handleConfirm}
                    loading={loading}
                    disabled={coupon === undefined}
                  >
                    {t('checkout.sections.coupon.apply')}
                  </Button>
                )}
              </SimpleBox>
              {error && (
                <SimpleBox mt={1}>
                  <div className="text red-text">{error.message}</div>
                </SimpleBox>
              )}
              {coupon && (
                <SimpleBox display="flex" alignItems="center" gap={9} mt={1}>
                  <div className={styles.couponSuccess}>
                    {t('checkout.sections.coupon.success', {
                      amount: coupon.discountType === 'FIXED_AMOUNT'
                        ? toMoney(coupon.discountAmount)
                        : toPercent(coupon.discountPercentage)
                    })}

                  </div>
                </SimpleBox>
              )}
            </FormField>
          </SimpleBox>
        </>
      )}
    </SimpleBox>
  )
}
export { Discount }
