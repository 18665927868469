import React, { useMemo } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney, toPercent } from '../../../shared/format'
import { calculateCouponDiscount as couponDiscount } from '../../../graphql/checkout'
import Decimal from 'decimal.js'
import { useTranslation } from 'react-i18next'

interface AddonCardProps {
  addon: ICartExtraFeatures
  isSelected: boolean
  coupon?: ChargebeeCoupon
  planPeriod: number
  freeMonths: number
  handleClickOnAdd?: (event: React.MouseEvent<HTMLElement>) => void
}

const AddonCard: React.FC<AddonCardProps> = ({
  addon,
  isSelected,
  coupon,
  planPeriod,
  freeMonths,
  handleClickOnAdd,
}) => {
  const { t } = useTranslation()
  const { id, title, description, imageUrl: icon, price } = addon
  const [originalPrice, priceWithDiscount] = useMemo(() => {
    const basePrice = new Decimal(price)
    const discountPrice = basePrice.minus(couponDiscount(basePrice, coupon))
    return [basePrice.div(planPeriod), discountPrice.div(planPeriod + freeMonths)].map(v => toMoney(v.toNumber()))
  }, [coupon, price, freeMonths])
  const couponDiscountValue = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  return (
    <div className={`addon-card${isSelected ? ' addon-card-selected' : ''}`} onClick={handleClickOnAdd}>
      <div className="detail">
        <div className="title">{t(`record.cart_extra_feature.${id}.title`, { defaultValue: title })}</div>
        <div className="subTitle">{t(`record.cart_extra_feature.${id}.description`, { defaultValue: description })}</div>
        <div className="logo">
          <Image as="img" src={icon} />
        </div>
      </div>
      <div className="addon-bottom-row">
        <Button className={`addon-button${isSelected ? '-selected' : ''}`}>
          {t(`checkout.sections.addons.${isSelected ? 'added' : 'add'}`)}
        </Button>
        {(originalPrice !== priceWithDiscount && (
          <div className="price-box price-discount-box">
            <div className="price">
              <p>for </p><span>{priceWithDiscount}</span>/mo
            </div>
            <div className="original-price-row">
              <div className="original-price">{originalPrice}/mo</div>
              <div className="save-box">
                Save {couponDiscountValue()}
              </div>
            </div>
          </div>
        )) || (
          <div className="price-box price">
            <span>{originalPrice}</span>/month
          </div>
        )}
      </div>
    </div>
  )
}

export default AddonCard
