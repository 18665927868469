import React, { useCallback, useState } from 'react'
import { FormField, Grid, GridRow } from 'semantic-ui-react'
import { validateEmailQuery } from '../../../graphql/checkout'
import { useTranslation } from 'react-i18next'
import { SimpleForm } from '../../SimpleForm'
import { handleEmailChange } from '../../../utils/checkout'
import { emailRegex } from '../../../const'
import { useLazyQuery } from '@apollo/client'
import { set } from 'dot-prop'
import { PaymentData } from '../../../fragments/payment/ICardProps'
import { CreateCheckoutSubscriptionResult } from '../../../types/checkout'

interface EmailFieldProps {
    checkoutConfig?: CreateCheckoutSubscriptionResult['createCheckoutSubscription']['checkoutConfiguration'];
    email:string;
    setData: (value: React.SetStateAction<PaymentData>) => void;
    className?: string;
    validEmail?: boolean;
    showHint?: boolean;
}

const EmailField: React.FC<EmailFieldProps> = ({
  checkoutConfig,
  email,
  setData,
  className,
  validEmail,
  showHint = true,
}) => {
  const { t } = useTranslation()
  const [emailError, setEmailError] = useState<boolean>(false)
  const [validateEmail, { loading: validatingEmail }] =
      useLazyQuery<{ result: boolean }>(validateEmailQuery)

  const handleValidateEmail = useCallback(() => {
    const preValid = email.length && emailRegex.test(email)

    if (!checkoutConfig?.validateEmail) {
      setData(d => ({ ...set(d, 'validEmail', preValid || undefined) }))
    } else if (email.length && emailRegex.test(email)) {
      validateEmail({ variables: { email: email } }).then(({ data }) => {
        setData(d => ({ ...set(d, 'validEmail', data?.result) }))
        setEmailError(data?.result || false)
      })
    } else {
      setData(d => ({ ...set(d, 'validEmail', undefined) }))
      setEmailError(true)
    }
  }, [email])

  return (
    <FormField className={className}>
      <label htmlFor="email">{t('checkout.sections.email.label')}</label>
      <Grid style={{ margin: 0 }}>
        <GridRow verticalAlign="middle" className="email-input-row">
          <SimpleForm.Input
            type="email"
            value={email}
            onChange={(d: any) => handleEmailChange(d.value, setData)}
            size="large"
            required
            fluid
            loading={validatingEmail}
            showValidation={emailError}
            handleInputBlur={handleValidateEmail}
          />
        </GridRow>
        {showHint && <GridRow verticalAlign="middle">
          <div className="text--x-small">
            {validEmail === false && (
              <span className="red-text">{t('checkout.sections.email.taken')}<br /></span>
            )}
            {t('checkout.sections.email.disclaimer')}
          </div>
        </GridRow>}
      </Grid>
    </FormField>
  )
}

export default EmailField
