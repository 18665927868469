/* eslint-disable complexity */
import React, { FC, useEffect, useCallback, useRef, useState, useMemo } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { set } from 'dot-prop'
import Decimal from 'decimal.js'
import { detect } from 'detect-browser'
import { useTranslation } from 'react-i18next'

import { Form, FormField, Grid, GridRow, Label, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleCard } from '../../components/SimpleCard'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'
import { observer } from '../../decorators'
import { Submit } from '../../fragments/payment/SubmitNew'
import Router from '../../shared/router'
import { CHARGEBEE_PAYMENT_SOURCE_TYPES as PAYMENT_TYPES, ChargebeeItemPrice } from '../../shared/types'
import { Plan } from '../../type'
import { useNavigation } from '../../hooks/useNavigation'
import { DiscountWarning } from '../../fragments/modal/payment/DiscountWarning'
import { ChargebeeCoupon, CouponData } from '../../utils/coupon'
import { ActivateNow } from '../../fragments/ActivateNow'
import { PaymentData } from '../../fragments/payment/ICardProps'
import { CardComponent } from '../../fragments/payment/CardComponent'
import {
  trackBeginCheckout,
  trackEmailValidationSuccess,
  trackPurchase,
} from '../../utils/tracking'
import { nameRegex, emailRegex } from '../../const'
import PaymentMethod from '../../components/Checkout/PaymentMethod'
import AddonCard from '../../components/Checkout/AddonCard'
import SummaryCard from '../../components/Checkout/SummaryCard'
import Tags from '../../components/Checkout/Tags'
import {
  applyCoupon,
  createCheckoutSubscription,
  queryPlan,
  queryUpsellItems,
  parsePayPalReturnData,
  calculateCouponDiscount,
  resolveFreeMonths,
  validateEmailQuery,
} from '../../graphql/checkout'
import Cookies from 'universal-cookie'
import {
  Feature,
  CreateCheckoutSubscriptionResult,
  queryCheckoutUpsellsResult,
} from '../../types/checkout'
import Session from '../../shared/storages/session'
import TrustpilotCard from '../../components/Checkout/TrustpilotCard/TrustpilotCard'
import { BlackFridayBanner } from '../../components/SeasonalDiscountBanner/BlackFridayBanner'
import { CurrencyPicker } from '../../components/CurrencyPicker'
import { LocalePicker } from '../../components/LocalePicker'
import { TOS } from '../../components/Checkout/TOS'
import EmailField from '../../components/Checkout/EmailField'

const I18N_ENABLED = process.env.I18N_ENABLED
const EXTRA_USER_ADDON = 'k5i47h4q'
const PLAN_PERIOD_MAP = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
}

const browserName = detect()?.name?.toLowerCase()
const withApplePay = (window as any).ApplePaySession || (browserName &&
  ['edge-ios', 'ios', 'safari', 'ios-webview'].indexOf(browserName) >= 0)

const cookies = new Cookies()

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PaymentGoogleImage = require('~assets/images/payment-google.svg')
const PaymentAppleImage = require('~assets/images/payment-apple.svg')
const ArrowUp = require('~assets/images/icons/icon-arrow-up-red.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down-red.svg')

export type Price = Pick<ChargebeeItemPrice, 'id' | 'name' | 'price' | 'trialPeriod'>
export type CardDetails = { number: string, expiry: string, cvv: string, token?: string }
type CouponWarningProps = { coupon: ChargebeeCoupon, plan: Plan }

type Props = { trialEnabled: boolean, promotion: boolean }
const CheckoutProduct: FC<Props> & { authorize: boolean; auth: string } = props => {
  // States & Variables
  const price = (Router.params.price as string) || ''
  const priceIds = price.split(':') || []
  const paypalData = parsePayPalReturnData()
  const qsEmail = (Router.qs.email as string) || ''

  const { setNav, resetNav } = useNavigation()
  const { t, i18n } = useTranslation()

  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const loadingState = useState<boolean>(!!paypalData.result)
  const [firstPlanId, setFirstPlanId] = useState<string>(priceIds[0])
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>(undefined)
  const [couponWarningPayload] = useState<CouponWarningProps>()
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_TYPES | undefined>(Router.qs.paypal ? 'PAYPAL' : undefined)
  const [addons, setAddons] = useState<Array<string>>(paypalData.addons || [])
  const [emailError, setEmailError] = useState<boolean>(false)
  const [data, setData] = useState<PaymentData>({
    email: (paypalData.email || qsEmail).trim().replace(/ /g, '+'),
    validEmail: undefined,
    firstName: (Router.qs.first as string) || '',
    lastName: (Router.qs.last as string) || '',
    zip: (Router.qs.zip as string) || '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })
  const [mobileOrderSummaryTabOpen, setMobileOrderSummaryTabOpen] = useState<boolean>(false)

  // GraphQL
  const [validateEmail, { loading: validatingEmail }] =
    useLazyQuery<{ result: boolean }>(validateEmailQuery)
  const [getPlan, { data: planData, called: planLoaded, loading: loadingPlan }] =
    useLazyQuery<{ plan: Plan }>(queryPlan)
  const [fetchUpsellItems, { data: upsellItems, loading: loadingUpsellItems }] =
    useLazyQuery<queryCheckoutUpsellsResult>(queryUpsellItems)
  const [startCheckout, { loading: loadingCheckoutConfig, data: checkoutSubscriptionData }] =
    useMutation<CreateCheckoutSubscriptionResult>(createCheckoutSubscription)
  const [getCouponData, { loading: couponLoading }] =
    useMutation<CouponData>(applyCoupon)

  // Shortcuts
  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const frequency = plan && { period: plan.period, periodUnit: PLAN_PERIOD_MAP[plan.periodUnit] }

  const checkoutConfig = checkoutSubscriptionData?.createCheckoutSubscription?.checkoutConfiguration
  const cartExtraFeatures = (checkoutConfig?.cartExtraFeatures || []).reduce((acc, feature) => {
    const addon = feature.chargebeeId !== EXTRA_USER_ADDON &&
      upsellItems?.addons.find(addon => addon.itemId === feature.chargebeeId)

    return addon ? [...acc, { ...feature, price: parseFloat(addon.price), addonId: addon.id }] : acc
  }, [])

  const limitUpsell = checkoutConfig?.regularUpsellLimit
  const extraUserAddon = checkoutConfig?.cartExtraFeatures?.find(feature => feature.chargebeeId === EXTRA_USER_ADDON) &&
    upsellItems?.addons.find(addon => addon.itemId === EXTRA_USER_ADDON)

  const addAnotherUser = extraUserAddon && addons.includes(extraUserAddon.itemId)
  const selectedFeatures: Feature[] = cartExtraFeatures.filter(feature => addons.includes(feature.chargebeeId))

  const addonsTotalPrice = selectedFeatures.reduce((acc, feature) => acc + feature.price, 0) +
    (addAnotherUser ? parseFloat(extraUserAddon?.price || '0') : 0)

  const buttonDisabled = paymentMethod === 'CARD' && cardErrors.length > 0
  const trialEnabled = props.trialEnabled && checkoutConfig?.payNowEnabled && Router.qs.noTrial === undefined
  const priceWithAddon = new Decimal(plan?.price || '0').add(new Decimal(addonsTotalPrice))
  const cartSteps = (checkoutConfig?.cartSteps || []).filter(step => (
    step.stepType !== 'extra_features' || cartExtraFeatures.length > 0
  ))

  const priceTotal = useMemo(() => (
    priceWithAddon.minus(calculateCouponDiscount(priceWithAddon, coupon)).toDP(2)
  ), [priceWithAddon, coupon])

  // Effects
  useEffect(() => {
    const checkoutDataCache = JSON.parse(localStorage.getItem('vs_checkout_cache') ?? '{}')
    if (!loadingState[0] && Object.keys(checkoutDataCache).length > 0) {
      setData(checkoutDataCache.data)
      setPaymentMethod(checkoutDataCache.type)
      checkoutDataCache.coupon && !Router.qs.coupon && setCoupon(checkoutDataCache.coupon)
      checkoutDataCache.activateNow !== undefined && setActivateNow(checkoutDataCache.activateNow)
    }

    const variables = { affiliateId: Session.affiliate.affiliateId || null, planId: firstPlanId }
    startCheckout({ variables }).then(({ data }) => {
      // Set currency and locale
      const [planId, planCurrency] = firstPlanId.split('-')
      Session.currency = planCurrency || Session.currency || 'USD'
      Session.locale = Session.locale || 'en'
      i18n.language !== Session.locale && i18n.changeLanguage(Session.locale)

      // Fetch plan data
      const priceId = ((planCurrency || Session.currency) !== 'USD') ? `${planId}-${Session.currency}` : firstPlanId
      priceId !== firstPlanId && setFirstPlanId(priceId)
      getPlan({ variables: { id: priceId } })

      // Prepare to load coupon
      const config = data?.createCheckoutSubscription?.checkoutConfiguration
      const variables: Hash = { priceId, currency: Session.currency }
      variables.code = paypalData.coupon || Router.qs.coupon || checkoutDataCache?.coupon?.name || config?.defaultCoupon
      variables.code && getCouponData({ variables }).then(({ data }) => {
        setCoupon(data?.coupon)
        data?.coupon && plan && trackBeginCheckout(plan, isFamily, data.coupon, 'coupon')
      })

      // Set primary payment method
      if (!checkoutDataCache.type) {
        const method = config?.cartPaymentMethods[0]?.paymentMethod
        method && setPaymentMethod(method === 'credit_card' ? 'CARD' : method.toUpperCase() as PAYMENT_TYPES)
      }

      // Load addons from URL param
      const urlAddons = (Router.qs.addons || '').toString().split(',')
      if (!addons.length && urlAddons.length && config?.cartExtraFeatures?.length) {
        setAddons(config.cartExtraFeatures.reduce((acc, feature) => {
          urlAddons.includes(feature.chargebeeId) && acc.push(feature.chargebeeId)
          return acc
        }, [] as string[]))
      }
    })

    setNav('no-sign-in')
    return resetNav
  }, [])

  useEffect(() => {
    const saveData = { ...data, email: qsEmail || data.email }
    localStorage.setItem('vs_checkout_cache', JSON.stringify({
      id: plan?.id, addons, coupon, activateNow, data: saveData, type: paymentMethod,
    }))
  }, [plan, addons, coupon, activateNow, data, paymentMethod])

  useEffect(() => {
    if (plan) {
      Session.currency = plan.currencyCode
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
      fetchUpsellItems({ variables: { ...frequency, currency: plan.currencyCode } })

      if (coupon) {
        const variables: Hash = { priceId: plan?.id, currency: Session.currency, code: coupon.name }
        getCouponData({ variables }).then(({ data }) => {
          setCoupon(data?.coupon)
          data?.coupon && trackBeginCheckout(plan, isFamily, data.coupon, 'coupon')
        })
      }
    }
  }, [plan])

  useEffect(() => {
    fieldsetRef.current && paypalData.result && fieldsetRef.current.querySelector('.submit-box')?.scrollIntoView()
  }, [fieldsetRef.current])

  useEffect(() => { !loadingState[0] && setActivateNow(!trialEnabled) }, [trialEnabled])

  const freeMonths = useMemo(() => resolveFreeMonths(props.promotion, checkoutConfig?.freeMonths), [checkoutConfig])
  const defaultPromo = useMemo(() => (
    activateNow && props.promotion && (typeof checkoutConfig?.freeMonths) !== 'number'
  ), [activateNow, checkoutConfig])

  // Callbacks
  const handleUpdatePlanPriceId = useCallback((id: string) => {
    const baseId = firstPlanId.split('-')[0]
    Router.updateHistory(`${location.pathname.split(baseId)[0]}${id}${window.location.search}`)
    getPlan({ variables: { id } })
    setFirstPlanId(id)
  }, [firstPlanId])

  const handleChangePaymentMethod = useCallback((type: PAYMENT_TYPES) => {
    setPaymentMethod(type === paymentMethod ? undefined : type)
  }, [paymentMethod])

  const handleDataChange = useCallback(({ name, value }: { name: string; value: any }) => {
    if ((name !== 'firstName' && name !== 'lastName') || (value === '' || nameRegex.test(value))) {
      setData(d => ({ ...set(d, name, value) }))
    }
  }, [])

  const handleEmailChange = useCallback((value: string) => {
    value = value.toLocaleLowerCase()
    emailRegex.test(value) && trackEmailValidationSuccess()
    setData(d => ({ ...set(d, 'email', value) }))
  }, [])

  const handleValidateEmail = useCallback(() => {
    const preValid = data.email.length && emailRegex.test(data.email)

    if (!checkoutConfig?.validateEmail) {
      setData(d => ({ ...set(d, 'validEmail', preValid || undefined) }))
    } else if (data.email.length && emailRegex.test(data.email)) {
      validateEmail({ variables: { email: data.email } }).then(({ data }) => {
        setData(d => ({ ...set(d, 'validEmail', data?.result) }))
        setEmailError(data?.result || false)
      })
    } else {
      setData(d => ({ ...set(d, 'validEmail', undefined) }))
      setEmailError(true)
    }
  }, [data])

  useEffect(() => {
    data.email.length && handleValidateEmail()
  }, [])

  const handleCouponChange = useCallback((coupon?: ChargebeeCoupon) => {
    setCoupon(coupon)

    if (coupon) {
      cookies.set('coupon', coupon.name, { path: '/' })
    } else {
      cookies.remove('coupon', { path: '/' })
    }

    const action = coupon ? 'add coupon' : 'remove coupon'
    plan && trackBeginCheckout(plan, isFamily, coupon, action)
  }, [plan])

  const handleSelectAddon = useCallback((addonId: string) => {
    if (addons.includes(addonId)) {
      setAddons(addons.filter(id => id !== addonId))
    } else {
      setAddons(addons.concat(addonId))
    }
  }, [addons])

  const handleToggleAddUser = useCallback(() => {
    !loadingState[0] && extraUserAddon && handleSelectAddon(extraUserAddon.itemId)
  }, [addons, extraUserAddon])

  const handleChangeCurrency = useCallback((currency: string) => {
    handleUpdatePlanPriceId(`${plan?.id.split('-')[0]}${currency === 'USD' ? '' : `-${currency}`}`)
  }, [plan])

  const applyWarnedCoupon = useCallback(() => {
    if (couponWarningPayload) {
      handleUpdatePlanPriceId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }, [couponWarningPayload])

  // Early return
  if (!planLoaded || loadingPlan || loadingUpsellItems || loadingCheckoutConfig || couponLoading) {
    return <div>loading</div>
  } else if (!plan) {
    Router.redirect('/pricing')
    return <></>
  }

  const submitMarkup = () => (
    <>
      {trialEnabled && (
        <ActivateNow
          months={months}
          activateNow={activateNow}
          setActivateNow={setActivateNow}
          bienniallyFreeMonths={freeMonths}
        />
      )}
      <SimpleBox mt={3} className="submit-box">
        <Submit
          type={paymentMethod}
          data={data}
          coupon={coupon}
          cardRef={cardRef}
          planDetail={frequency}
          captchaRef={captchaRef}
          priceIds={[plan?.id, ...addons]}
          promotion={defaultPromo}
          disabled={buttonDisabled}
          activateNow={activateNow}
          loadingState={loadingState}
          price={priceTotal.toNumber()}
          checkoutConfigId={checkoutConfig?.id}
          subscriptionId={checkoutSubscriptionData?.createCheckoutSubscription?.id}
          addonIds={selectedFeatures.map(feature => feature.addonId).concat(addAnotherUser ? [EXTRA_USER_ADDON] : [])}
          paypalReturnPath={`/${location.pathname.split('/')[1]}`}
          purchaseFinished={() => trackPurchase(plan, isFamily, activateNow, coupon)}
        />
      </SimpleBox>
      <SimpleBox mt={3}><TOS /></SimpleBox>
    </>
  )

  const steps: any = {
    'email': {
      title: (
        <SimpleText size="title3" color="black">
          <span className="checkout-order-title">
            {t('checkout.sections.email.title')}
          </span>
        </SimpleText>
      ),
      body: (
        <SimpleBox mt={3}>
          <SimpleCard fluid padding="heavy" className="card-content">
            <EmailField
              checkoutConfig={checkoutConfig}
              email={data.email}
              validEmail={data.validEmail}
              setData={setData}
              className="checkout-v3-email"
            />
          </SimpleCard>
        </SimpleBox>
      ),
    },
    'extra_features': {
      title: (
        <>
          <SimpleText size="title3" color="black">
            <span className="checkout-order-title">
              {t('checkout.sections.addons.title')}
            </span>
          </SimpleText>
          <Label style={{ background: '#E8EAF0' }} className="checkout-extra-label">
            <SimpleText color="grey" size="textSmall">
              <span className="checkout-optional-text">
                {t('checkout.sections.addons.optional')}
              </span>
            </SimpleText>
          </Label>
        </>
      ),
      body: (
        <SimpleBox className="addon-card-row">
          <SimpleBox
            mt={3}
            display="flex"
            flexDirection="row"
            className="addon-row"
          >

            {cartExtraFeatures.slice(0, limitUpsell).map(feature => (
              <AddonCard
                addon={feature}
                key={feature.id}
                handleClickOnAdd={event => {
                  event.preventDefault()
                  handleSelectAddon(feature.chargebeeId)
                  event.currentTarget.blur()
                }}
                isSelected={addons.includes(feature.chargebeeId)}
                coupon={coupon}
                planPeriod={plan.period}
                freeMonths={activateNow ? freeMonths : 0}
              />
            ))}
          </SimpleBox>
        </SimpleBox>
      ),
    },
    'payment_methods': {
      title: (
        <SimpleText size="title3" color="black">
          <span className="checkout-order-title">
            {t('checkout.sections.payment_method.title')}
          </span>
        </SimpleText>
      ),
      body: {
        'credit_card': (
          <PaymentMethod
            title={t('checkout.sections.payment_method.card.title')}
            icon={<img height={20} src={PaymentCardsImage} alt="cards" className="payment-icon" />}
            isCardOpen={paymentMethod === 'CARD'}
            handleToggleCard={() => handleChangePaymentMethod('CARD')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              <CardComponent
                data={data}
                forwardRef={cardRef}
                captchaRef={captchaRef}
                onChange={handleDataChange}
                onErrors={setCardErrors}
              />
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        'paypal': (
          <PaymentMethod
            title={t('checkout.sections.payment_method.paypal.title')}
            icon={<img height={20} src={PaymentPayPalIcon} alt="paypal" className="payment-icon" />}
            isCardOpen={paymentMethod === 'PAYPAL'}
            handleToggleCard={() => handleChangePaymentMethod('PAYPAL')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        'google_pay': (!withApplePay && (
          <PaymentMethod
            title={t('checkout.sections.payment_method.google_pay.title')}
            icon={
              <div className="payment-icon-box">
                <img height={12} src={PaymentGoogleImage} alt="google" />
              </div>
            }
            isCardOpen={paymentMethod === 'GOOGLE_PAY'}
            handleToggleCard={() => handleChangePaymentMethod('GOOGLE_PAY')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        )),
        'apple_pay': (withApplePay && (
          <PaymentMethod
            title={t('checkout.sections.payment_method.apple_pay.title')}
            icon={
              <div className="payment-icon-box">
                <img height={10} src={PaymentAppleImage} alt="apple" />
              </div>
            }
            isCardOpen={paymentMethod === 'APPLE_PAY'}
            handleToggleCard={() => handleChangePaymentMethod('APPLE_PAY')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        )),
      },
    },
  }

  const renderAllPaymentMethods = () => (checkoutConfig?.cartPaymentMethods?.map(entry => (
    <React.Fragment key={entry.id}>{steps['payment_methods'].body[entry.paymentMethod]}</React.Fragment>
  )))

  const renderSummaryCard = (mobile: boolean) => (plan && (
    <SummaryCard
      plan={plan}
      total={priceTotal.toNumber()}
      coupon={coupon}
      activateNow={activateNow}
      firstPlanId={firstPlanId}
      extraUserAddon={extraUserAddon}
      addAnotherUser={!!addAnotherUser}
      handleToggleAddUser={handleToggleAddUser}
      handleCouponChange={handleCouponChange}
      selectedFeatures={selectedFeatures}
      bienniallyFreeMonths={freeMonths}
      priceWithAddon={priceWithAddon.toNumber()}
      allowCoupon={checkoutConfig?.allowApplyCoupon}
      seasonalDiscountBanner={checkoutConfig?.seasonalDiscountBanner && !(mobile && checkoutConfig?.orderSummaryDrawer)}
    />
  ))

  return (
    <fieldset disabled={loadingState[0]} ref={fieldsetRef}>
      {/* modal */}
      <Modal size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)} closeIcon>
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => applyWarnedCoupon()}
        />
      </Modal>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">{t('checkout.header.title')}</SimpleText>
      </div>
      <Tags
        trialEnabled={trialEnabled}
        activateNow={activateNow}
        reduced={checkoutConfig?.reducedMoneyBackGuarantee}
      />
      {checkoutConfig?.orderSummaryDrawer && (
        <div className="checkout-mobile-summary-tab">
          <div
            className="checkout-mobile-summary-tab-header"
            onClick={() => {
              setMobileOrderSummaryTabOpen(!mobileOrderSummaryTabOpen)
            }}>
            <span>Order Summary</span>
            <div className="checkout-mobile-summary-tab-icon-wrapper">
              <img src={mobileOrderSummaryTabOpen ? ArrowDown : ArrowUp} />
            </div>
          </div>
          {mobileOrderSummaryTabOpen && renderSummaryCard(true)}
        </div>
      )}
      <SimpleBox className="checkout checkout-new" display="flex" justifyContent="center">
        <SimpleBox maxWidth={1280} mx="auto" display="flex" flexDirection="row" gap={21} justifyContent="center">
          {!loadingCheckoutConfig && cartSteps?.length > 0 && (
            <Form name="checkout" className="checkout-first-block">
              {coupon && checkoutConfig?.seasonalDiscountBanner && (
                <SimpleBox marginBottom={18} className="show-on-tablet-down">
                  <BlackFridayBanner coupon={coupon} title="Holiday Special" />
                </SimpleBox>
              )}
              {/* account */}
              <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
                <SimpleBox className="checkout-order-circle">
                  <SimpleText size="title2" color="white" weight="bold"></SimpleText>
                </SimpleBox>
                {steps[cartSteps[0].stepType as string].title}
              </SimpleBox>
              {steps[cartSteps[0].stepType as string].body}
              {!checkoutConfig?.orderSummaryDrawer && (
                <div className="checkout-summary-tablet">
                  <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
                    <SimpleBox className="checkout-order-circle">
                      <SimpleText size="title2" color="white" weight="bold"></SimpleText>
                    </SimpleBox>
                    <SimpleText size="title3" color="black">
                      <span className="checkout-order-title">
                        Selected Plan
                      </span>
                    </SimpleText>
                  </SimpleBox>
                  {renderSummaryCard(true)}
                  <TrustpilotCard className="show-on-web" />
                </div>
              )}
              {cartSteps.slice(1).map((step: any, index: number) => {
                const isCurrentStepPaymentMethods = step.stepType === 'payment_methods'
                return (
                  <React.Fragment key={index}>
                    <SimpleBox mt={5} display="flex" alignItems="center" gap={12} width="100%">
                      <SimpleBox className="checkout-order-circle">
                        <div className="show-on-desktop-only">
                          <SimpleText size="title2" color="white"></SimpleText>
                        </div>
                        <div className="show-on-tablet-down">
                          <SimpleText size="title2" color="white"></SimpleText>
                        </div>
                      </SimpleBox>
                      {steps[step.stepType].title}
                    </SimpleBox>
                    {(isCurrentStepPaymentMethods && renderAllPaymentMethods()) || steps[step.stepType].body}
                  </React.Fragment>
                )
              })}
              <TrustpilotCard className="show-on-mobile" />
            </Form>
          )}
          {plan && (
            <SimpleBox className="order-summary-desktop">
              <SimpleBox display="flex" justifyContent="flex-start" alignItems="center" height={32} marginBottom={18}>
                <SimpleText size="title3">{t('checkout.sections.summary.title')}</SimpleText>
              </SimpleBox>
              {renderSummaryCard(false)}
              <TrustpilotCard className="show-on-web" />
              {I18N_ENABLED && (
                <SimpleBox display="flex" justifyContent="space-between" marginTop={18} paddingInline={16}>
                  <LocalePicker icon="globe" flag />
                  <CurrencyPicker options={plan.availableCurrencies} onChange={handleChangeCurrency} sign />
                </SimpleBox>
              )}
            </SimpleBox>
          )}
        </SimpleBox>
      </SimpleBox>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true,
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
