import React from 'react'
import { Plan } from '../../../type'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { calculatePriceAfterCoupon } from '../../../graphql/checkout'
import { SimpleBox } from '../../SimpleBox'
import { toMoney, toMoneyWithoutCalculation } from '../../../shared/format'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

const AntivirusProImage = require('~assets/images/antivirus-pro.svg')
const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')

interface PlanDetailProps {
  plan: Plan;
  coupon?: ChargebeeCoupon;
  activateNow: boolean;
  freeMonths: number;
  addAnotherUser?: boolean;
}

const PlanDetail: React.FC<PlanDetailProps> = ({
  plan,
  coupon,
  activateNow,
  freeMonths,
  addAnotherUser
}) => {
  const { t } = useTranslation()
  const planPeriod = plan?.period || 12
  const nameParts = (plan?.name || '')?.replace(/\)$/, '')?.split('(')
  const planPrice = parseFloat(plan?.price || '0')
  const planPricePerMonth = planPrice / (planPeriod + ((activateNow && freeMonths) || 0))
  const planPriceWithCoupon = calculatePriceAfterCoupon(planPrice, coupon)
  const totalPricePerMonth = (planPriceWithCoupon / (planPeriod + ((activateNow && freeMonths) || 0)))
  const userCount = (plan?.item?.users || 1) + (addAnotherUser ? 1 : 0)

  const totalPricePerMonthToMoney = toMoney(totalPricePerMonth)

  return (

    <div className="body-plan">
      <div className="body-plan-img" >
        <img src={AntivirusProImage} alt="antivirus" />
      </div>
      <div className="body-plan-right">
        <div className="body-plan-detail">
          <img src={ShieldRedIcon} alt="shield" width={42} />
          <span className="body-plan-detail-name">{nameParts[0]}</span>
          <span className="body-plan-detail-period">({nameParts[1]})</span>
          {activateNow && freeMonths > 0 && (
            <SimpleBox
              className="checkout-free-text"
              background="#E8F7EB"
              borderRadius={10}
              padding="4px 10px"
            >
              {t('checkout.sections.summary.months_free_count', { count: freeMonths })}
            </SimpleBox>
          )}
        </div>
        <div className="body-plan-price">
          <span className="body-plan-price-total">{toMoney(planPriceWithCoupon)}</span>
          <span className="body-plan-price-monthly">{t('checkout.sections.summary.with_discount', { price: totalPricePerMonthToMoney })}</span>
        </div>
        <div className="body-plan-price-mobile">
          <div className="body-plan-price-mobile-top">
            <span className="checkout-breakdown-amount">
              {t('checkout.sections.summary.user_count', { count: userCount })}
            </span>
            <div className="body-plan-price-mobile-top-user-prices">
              {coupon && <span className="checkout-gray-text">{toMoneyWithoutCalculation(planPrice)}</span>}
              <span className="checkout-breakdown-amount">{toMoney(planPriceWithCoupon)}</span>
            </div>
          </div>
          <div className="body-plan-price-mobile-bottom">
            {activateNow && freeMonths > 0 && (
              <SimpleBox
                className="checkout-free-text"
                background="#E8F7EB"
                borderRadius={10}
                padding="4px 10px"
              >
                {t('checkout.sections.summary.months_free_count', { count: freeMonths })}
              </SimpleBox>
            )}
            <Markdown className="body-plan-price-monthly">
              {t('checkout.sections.summary.with_discount_origin', (
                coupon ? ({
                  // eslint-disable-next-line camelcase
                  origin_price: `~~${toMoney(planPricePerMonth)}~~`,
                  price: totalPricePerMonthToMoney
                }) : ({
                  // eslint-disable-next-line camelcase
                  origin_price: '',
                  price: totalPricePerMonthToMoney
                })
              ))}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanDetail
