import React from 'react'
import AddonCard from '../../Checkout/AddonCardV2'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { Plan } from '../../../type'

type CART_EXTRA_FEATURES = {
    price: number;
    addonId: string;
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    chargebeeId: string;
}

interface AddonRowProps {
    cartExtraFeatures:CART_EXTRA_FEATURES[]
    limitUpsell?: number
    handleSelectAddon: (chargebeeId: string) => void
    addons: string[]
    coupon?: ChargebeeCoupon
    plan: Plan
    freeMonths: number
    activateNow: boolean
}

const AddonRow: React.FC<AddonRowProps> = ({
  cartExtraFeatures,
  limitUpsell,
  handleSelectAddon,
  addons,
  coupon,
  plan,
  freeMonths,
  activateNow
}) => (
  <div className="body-addons">
    {cartExtraFeatures.slice(0, limitUpsell).map(feature => (
      <AddonCard
        key={feature.id}
        addon={feature}
        handleClickOnAdd={event => {
          event.preventDefault()
          handleSelectAddon(feature.chargebeeId)
          event.currentTarget.blur()
        }}
        isSelected={addons.includes(feature.chargebeeId)}
        coupon={coupon}
        planPeriod={plan.period}
        freeMonths={activateNow ? freeMonths : 0}
      />
    ))}
  </div>
)

export default AddonRow
