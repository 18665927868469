import { emailRegex } from '../const'
import { trackEmailValidationSuccess } from './tracking'
import {set} from 'dot-prop'
import { PaymentData } from '../fragments/payment/ICardProps'

export const handleEmailChange = (value: string, setData:(value: React.SetStateAction<PaymentData>) => void): void => {
  value = value.toLocaleLowerCase()
  emailRegex.test(value) && trackEmailValidationSuccess()
  setData(d => ({ ...set(d, 'email', value) }))
}
