/* eslint-disable complexity */
import React, { FC, useEffect, useCallback, useRef, useState, useMemo } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { set } from 'dot-prop'
import Decimal from 'decimal.js'
import { detect } from 'detect-browser'

import { Form, FormField, Grid, GridRow, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'
import { observer } from '../../decorators'
import { Submit } from '../../fragments/payment/SubmitNew'
import Router from '../../shared/router'
import { ChargebeeItemPrice } from '../../shared/types'
import { Plan } from '../../type'
import { useNavigation } from '../../hooks/useNavigation'
import { DiscountWarning } from '../../fragments/modal/payment/DiscountWarning'
import { ChargebeeCoupon, CouponData } from '../../utils/coupon'
import { ActivateNow } from '../../fragments/ActivateNow'
import { PaymentData } from '../../fragments/payment/ICardProps'
import { CardComponent } from '../../fragments/payment/CardComponent'
import {
  trackBeginCheckout,
  trackPurchase,
} from '../../utils/tracking'
import { nameRegex } from '../../const'
import Tags from '../../components/Checkout/Tags'
import {
  applyCoupon,
  createCheckoutSubscription,
  queryPlan,
  queryUpsellItems,
  parsePayPalReturnData,
  calculateCouponDiscount,
  resolveFreeMonths,
} from '../../graphql/checkout'
import {
  Feature,
  CreateCheckoutSubscriptionResult,
  queryCheckoutUpsellsResult,
} from '../../types/checkout'
import Session from '../../shared/storages/session'
import { TrustpilotTopBlock } from '../../components/Checkout/TrustpilotCard/TrustpilotCard'
import PlanDetail from '../../components/CheckoutV3/PlanDetail'
import SummaryCard from '../../components/CheckoutV3/SummaryCard'
import AddonRow from '../../components/CheckoutV3/AddonRow'
import { useTranslation } from 'react-i18next'
import { TOS } from '../../components/Checkout/TOS'
import EmailField from '../../components/Checkout/EmailField'

const EXTRA_USER_ADDON = 'k5i47h4q'
const PLAN_PERIOD_MAP = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
}

const browserName = detect()?.name?.toLowerCase()
const withApplePay = (window as any).ApplePaySession || (browserName &&
  ['edge-ios', 'ios', 'safari', 'ios-webview'].indexOf(browserName) >= 0)


const PaymentPayPalIcon = require('~assets/images/payment-paypal-seek.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PaymentGoogleImage = require('~assets/images/payment-google-white.svg')
const PaymentAppleImage = require('~assets/images/payment-apple-white.svg')
const AppEsteemImage = require('~assets/images/app-esteem.svg')
const VB100Image = require('~assets/images/vb-100.svg')
const SSLImage = require('~assets/images/ssl.svg')
const ArrowUp = require('~assets/images/icons/icon-arrow-up-red.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down-red.svg')

export type Price = Pick<ChargebeeItemPrice, 'id' | 'name' | 'price' | 'trialPeriod'>
export type CardDetails = { number: string, expiry: string, cvv: string, token?: string }
type CouponWarningProps = { coupon: ChargebeeCoupon, plan: Plan }

type Props = { trialEnabled: boolean, promotion: boolean }
const CheckoutProduct: FC<Props> & { authorize: boolean; auth: string } = props => {
  // States & Variables
  const price = Router.params.price as string
  const priceIds = price?.split(':') || []
  const paypalData = parsePayPalReturnData()
  const qsEmail = (Router.qs.email as string) || ''

  const { setNav, resetNav } = useNavigation()
  const { t, i18n } = useTranslation()

  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const loadingState = useState<boolean>(!!paypalData.result)
  const [firstPlanId, setFirstPlanId] = useState<string>(priceIds[0])
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>(undefined)
  const [couponWarningPayload] = useState<CouponWarningProps>()
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [addons, setAddons] = useState<Array<string>>(paypalData.addons || [])
  const [data, setData] = useState<PaymentData>({
    email: (paypalData.email || qsEmail).trim().replace(/ /g, '+'),
    validEmail: undefined,
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })
  const [showMobileOrderSummary, setShowMobileOrderSummary] = useState<boolean>(false)

  // GraphQL
  const [getPlan, { data: planData, called: planLoaded, loading: loadingPlan }] =
    useLazyQuery<{ plan: Plan }>(queryPlan)
  const [fetchUpsellItems, { data: upsellItems, loading: loadingUpsellItems }] =
    useLazyQuery<queryCheckoutUpsellsResult>(queryUpsellItems)
  const [startCheckout, { loading: loadingCheckoutConfig, data: checkoutSubscriptionData }] =
    useMutation<CreateCheckoutSubscriptionResult>(createCheckoutSubscription)
  const [getCouponData, { loading: couponLoading }] =
    useMutation<CouponData>(applyCoupon)

  // Shortcuts
  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const frequency = plan && { period: plan.period, periodUnit: PLAN_PERIOD_MAP[plan.periodUnit] }

  const checkoutConfig = checkoutSubscriptionData?.createCheckoutSubscription?.checkoutConfiguration
  const cartExtraFeatures = (checkoutConfig?.cartExtraFeatures || []).reduce((acc, feature) => {
    const addon = feature.chargebeeId !== EXTRA_USER_ADDON &&
      upsellItems?.addons.find(addon => addon.itemId === feature.chargebeeId)

    return addon ? [...acc, { ...feature, price: parseFloat(addon.price), addonId: addon.id }] : acc
  }, [])

  const extraUserAddon = checkoutConfig?.cartExtraFeatures?.find(feature => feature.chargebeeId === EXTRA_USER_ADDON) &&
    upsellItems?.addons.find(addon => addon.itemId === EXTRA_USER_ADDON)

  const addAnotherUser = extraUserAddon && addons.includes(extraUserAddon.itemId)
  const selectedFeatures: Feature[] = cartExtraFeatures.filter(feature => addons.includes(feature.chargebeeId))

  const addonsTotalPrice = selectedFeatures.reduce((acc, feature) => acc + feature.price, 0) +
    (addAnotherUser ? parseFloat(extraUserAddon?.price || '0') : 0)

  const buttonDisabled = cardErrors.length > 0
  const trialEnabled = props.trialEnabled && checkoutConfig?.payNowEnabled && Router.qs.noTrial === undefined
  const priceWithAddon = new Decimal(plan?.price || '0').add(new Decimal(addonsTotalPrice))
  const priceTotal = useMemo(() => (
    priceWithAddon.minus(calculateCouponDiscount(priceWithAddon, coupon)).toDP(2)
  ), [priceWithAddon, coupon])


  // Effects
  useEffect(() => {
    const checkoutDataCache = JSON.parse(localStorage.getItem('vs_checkout_v3_cache') ?? '{}')
    if (!loadingState[0] && Object.keys(checkoutDataCache).length > 0) {
      setData(checkoutDataCache.data)
      checkoutDataCache.coupon && setCoupon(checkoutDataCache.coupon)
      checkoutDataCache.addons?.length && setAddons(checkoutDataCache.addons)
      checkoutDataCache.activateNow !== undefined && setActivateNow(checkoutDataCache.activateNow)
    }

    const variables = { affiliateId: Session.affiliate.affiliateId || null, planId: firstPlanId }
    startCheckout({ variables }).then(({ data }) => {
      // Set currency and locale
      const [planId, planCurrency] = firstPlanId.split('-')
      Session.currency = planCurrency || Session.currency || 'USD'
      Session.locale = Session.locale || 'en'
      i18n.language !== Session.locale && i18n.changeLanguage(Session.locale)

      // Fetch plan data
      const priceId = ((planCurrency || Session.currency) !== 'USD') ? `${planId}-${Session.currency}` : firstPlanId
      priceId !== firstPlanId && setFirstPlanId(priceId)
      getPlan({ variables: { id: priceId } })


      const config = data?.createCheckoutSubscription?.checkoutConfiguration
      const variables: Hash = { priceId: firstPlanId, currency: Session.currency }
      variables.code = paypalData.coupon || Router.qs.coupon || checkoutDataCache?.coupon?.name || config?.defaultCoupon
      variables.code && getCouponData({ variables }).then(({ data }) => {
        if (data?.coupon) {
          setCoupon(data.coupon)
          plan && trackBeginCheckout(plan, isFamily, coupon, 'coupon')
        }
      })

      const urlAddons = (Router.qs.addons || '').toString().split(',')
      if (!addons.length && urlAddons.length && config?.cartExtraFeatures?.length) {
        setAddons(config.cartExtraFeatures.reduce((acc, feature) => {
          urlAddons.includes(feature.chargebeeId) && acc.push(feature.chargebeeId)
          return acc
        }, [] as string[]))
      }
    })

    setNav('no-sign-in')
    return resetNav
  }, [])

  useEffect(() => {
    const saveData = { ...data, email: qsEmail || data.email }
    localStorage.setItem('vs_checkout_v3_cache', JSON.stringify({
      id: firstPlanId, addons, coupon, activateNow, data: saveData,
    }))
  }, [firstPlanId, addons, coupon, activateNow, data])

  useEffect(() => {
    if (plan) {
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
      plan.currencyCode && (Session.currency = plan.currencyCode)
      fetchUpsellItems({ variables: { ...frequency, currency: plan.currencyCode } })
    }
  }, [plan])

  useEffect(() => {
    fieldsetRef.current && paypalData.result && fieldsetRef.current.querySelector('.submit-box')?.scrollIntoView()
  }, [fieldsetRef.current])

  useEffect(() => { !loadingState[0] && setActivateNow(!trialEnabled) }, [trialEnabled])

  const freeMonths = useMemo(() => resolveFreeMonths(props.promotion, checkoutConfig?.freeMonths), [checkoutConfig])
  const defaultPromo = useMemo(() => (
    activateNow && props.promotion && (typeof checkoutConfig?.freeMonths) !== 'number'
  ), [activateNow, checkoutConfig])

  //new checkout-v3

  const handleDataChange = useCallback(({ name, value }: { name: string; value: any }) => {
    if ((name !== 'firstName' && name !== 'lastName') || (value === '' || nameRegex.test(value))) {
      setData(d => ({ ...set(d, name, value) }))
    }
  }, [])

  const handleSelectAddon = useCallback((addonId: string) => {
    if (addons.includes(addonId)) {
      setAddons(addons.filter(id => id !== addonId))
    } else {
      setAddons(addons.concat(addonId))
    }
  }, [addons])

  const handleToggleAddUser = useCallback(() => {
    !loadingState[0] && extraUserAddon && handleSelectAddon(extraUserAddon.itemId)
  }, [addons, extraUserAddon])

  const applyWarnedCoupon = useCallback(() => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }, [couponWarningPayload])

  const handleToggleShowMobileOrderSummary = useCallback(() => {
    setShowMobileOrderSummary(!showMobileOrderSummary)
  }, [showMobileOrderSummary])

  // Early return
  if (!planLoaded || loadingPlan || loadingUpsellItems || loadingCheckoutConfig || couponLoading) {
    return <div>loading</div>
  } else if (!plan) {
    Router.redirect('/pricing')
    return <></>
  }

  const renderSummaryCard = () => (
    <SummaryCard
      plan={plan}
      extraUserAddon={extraUserAddon}
      handleToggleAddUser={handleToggleAddUser}
      addAnotherUser={addAnotherUser}
      coupon={coupon}
      activateNow={activateNow}
      freeMonths={freeMonths}
      priceTotal={priceTotal}
      selectedFeatures={selectedFeatures}
      priceWithAddon={priceWithAddon}
      checkoutConfig={checkoutConfig}
      firstPlanId={firstPlanId}
      setCoupon={setCoupon}
    />
  )


  return (
    <fieldset disabled={loadingState[0]} style={{ margin: 0, padding: 0, border: 'none' }} ref={fieldsetRef}>
      {/* modal */}
      <Modal size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)} closeIcon>
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => applyWarnedCoupon()}
        />
      </Modal>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">
          {t('checkout.header.title')}
        </SimpleText>
      </div>
      <Tags
        trialEnabled={trialEnabled}
        activateNow={activateNow}
        reduced={checkoutConfig?.reducedMoneyBackGuarantee}
      />
      <div className="checkout-v3">
        <div className="body">
          <PlanDetail
            plan={plan}
            coupon={coupon}
            activateNow={activateNow}
            freeMonths={freeMonths}
            addAnotherUser={addAnotherUser}
          />
          {cartExtraFeatures?.length > 0 && <AddonRow
            cartExtraFeatures={cartExtraFeatures}
            limitUpsell={checkoutConfig?.regularUpsellLimit}
            handleSelectAddon={handleSelectAddon}
            addons={addons}
            coupon={coupon}
            plan={plan}
            freeMonths={freeMonths}
            activateNow={activateNow}
          />}
          <div className="body-summary-card">
            {renderSummaryCard()}
          </div>
          <div className="body-bottom">
            <div className="body-bottom-icons">
              <img src={AppEsteemImage} alt="app-esteem" />
              <img src={VB100Image} alt="vb-100" />
              <img src={SSLImage} alt="ssl" /></div>
            <TrustpilotTopBlock />
          </div>
        </div>
        <Form name="checkout-v3" className="payments">
          {trialEnabled && (
            <ActivateNow
              months={months}
              activateNow={activateNow}
              setActivateNow={setActivateNow}
              bienniallyFreeMonths={freeMonths}
            />
          )}
          <div className="payments-others">
            <div className="payments-others-paypal">
              <img height={20} src={PaymentPayPalIcon} alt="paypal" />
            </div>
            {!withApplePay && <div className="payments-others-google">
              <img height={12} src={PaymentGoogleImage} alt="google" />
            </div>}
            {withApplePay && <div className="payments-others-apple">
              <img height={10} src={PaymentAppleImage} alt="apple" />
            </div>}
          </div>
          <div className="payments-or-divider">
            <span>or</span>
            <div />
          </div>
          <div className="payments-or-cards-icon">
            <img height={28} src={PaymentCardsImage} alt="cards" />
          </div>
          <CardComponent
            data={data}
            forwardRef={cardRef}
            captchaRef={captchaRef}
            onChange={handleDataChange}
            onErrors={setCardErrors}
            withPlaceholder={false}
          />
          <EmailField
            checkoutConfig={checkoutConfig}
            email={data.email}
            validEmail={data.validEmail}
            setData={setData}
            className="email"
            showHint={false}
          />
          <SimpleBox className="submit-box">
            <Submit
              type="CARD"
              data={data}
              coupon={coupon}
              cardRef={cardRef}
              planDetail={frequency}
              captchaRef={captchaRef}
              priceIds={[firstPlanId, ...addons]}
              promotion={defaultPromo}
              disabled={buttonDisabled}
              activateNow={activateNow}
              loadingState={loadingState}
              price={priceTotal.toNumber()}
              checkoutConfigId={checkoutConfig?.id}
              subscriptionId={checkoutSubscriptionData?.createCheckoutSubscription?.id}
              addonIds={selectedFeatures.map(feature => feature.addonId).concat(addAnotherUser ? [EXTRA_USER_ADDON] : [])}
              paypalReturnPath={`/${location.pathname.split('/')[1]}`}
              purchaseFinished={() => trackPurchase(plan, isFamily, activateNow, coupon)}
            />
          </SimpleBox>
          <SimpleBox>
            <TOS />
          </SimpleBox>
        </Form>
        <div className="order-summary-mobile">
          <div className="order-summary-mobile-header" onClick={handleToggleShowMobileOrderSummary}>
            <span>{t('checkout.sections.summary.title')}</span>
            <div className="checkout-mobile-summary-tab-icon-wrapper">
              <img src={showMobileOrderSummary ? ArrowDown : ArrowUp} />
            </div>
          </div>
          {showMobileOrderSummary && renderSummaryCard()}
        </div>
      </div>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true,
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
